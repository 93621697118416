import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  url1='https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/%D8%AC%D8%A8%D9%86%D8%A9%20%D9%85%D8%B4%D9%84%D9%84%D8%A9.jpeg?alt=media&token=22d44420-ec71-47cb-828f-bd43eb01c14e'
url2="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/%D8%AC%D8%A8%D9%86%D8%A9%20%D9%85%D9%88%D8%B2%D8%A7%D8%B1%D9%8A%D9%84%D8%A7.jpg?alt=media&token=769a1a12-496e-4a8f-9c61-ecfdc189f822"
url3="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/%D9%84%D8%A8%D9%86.jpg?alt=media&token=d35cb7b6-d37c-40ac-815c-40e9be632541"
  items1=[this.url1,this.url2,this.url3]
  url4="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/7.jpg?alt=media&token=92d65a91-f663-46e7-afa9-fec1bb3209f8"
  url5="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/3.jpg?alt=media&token=6dffaa57-af67-4636-9aa1-4c442a77ca4d"
  url6="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/6.jpg?alt=media&token=7b71886b-f308-44a5-8d3c-f25a2e36a8b1"
  items2=[this.url4,this.url5,this.url6]
lang
constructor() { }

  ngOnInit(): void {
    window.scrollTo({  top: 0,
      left: 0,
      behavior: 'smooth'});
      this.lang=AppComponent.lang
  }

}
