import { Component, OnInit } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {
  location1="Atakent, 2007. Sk. No:2, 27580 Şehitkamil/Gaziantep"
  location2="https://www.google.com/maps/embed/v1/place?key=AIzaSyDqEXgrY-4vP7t_XDsrcolnOu83uRQmU1g&q="
 locationn="https://www.google.com/maps/embed/v1/place?key=AIzaSyDqEXgrY-4vP7t_XDsrcolnOu83uRQmU1g&q=Atakent, 2007. Sk. No:2, 27580 Şehitkamil/Gaziantep"
 locationn1
 storedata={
    
  viewstatus:'5',
  sid:'',
  password:'',
  coverurl:'',
  storename:'',
  numberphone:'',
  numbercountry:'',
  whatsapp:'',
  wnumbercountry:'',
  facebook:'',
  city:'',
  address:'',
  lat:null,
  lng:null,
  accuracy:null,
  storecomment:'',
  date:'',
  time:'',
  datestart:'',
  timestart:'',
 db1:'',
 db2:'',
 db3:'',
 vip:'',
}
locations:boolean=true
lang
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.lang=AppComponent.lang
    window.scrollTo({  top: 0,
      left: 0,
      behavior: 'smooth'});
     this.locationn=this.location2+this.location1
      this.locationn1= this.sanitizer.bypassSecurityTrustResourceUrl(this.locationn);

    }
    findMe1() {
     // if(!this.storedata.accuracy){
       // this.toastr.info(' لتحديد موقعك الحالي يجب تشغيل الموقع في جهازك  ' )
    //   alert(' لتحديد موقعك الحالي يجب تشغيل الموقع في جهازك  ' )
    //  }
    console.log(navigator.geolocation)
      if (navigator.geolocation) { 
        this.locations=true
        navigator.geolocation.getCurrentPosition((position) => {
          //this.showPosition(position);
          //console.log(position)
          console.log(navigator.geolocation)
          this.storedata.lng=position.coords.longitude
         this.storedata.lat=position.coords.latitude
         this.storedata.accuracy=position.coords.accuracy
         this.storedata.accuracy=parseInt( this.storedata.accuracy)
         console.log(position)
      
        
        });
      } else {
        alert(' لتحديد موقعك الحالي  يجب تشغيل الموقع في جهازك  ' )
        this.locations=false
        //console.log("Geolocation is not supported by this browser.");
      }
      if(!this.locations){  alert(' لتحديد موقعك الحالي  يجب تشغيل الموقع في جهازك  ' )}
    }
   
     findMe() {
   
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.showPosition,this.showError);
      } else {
        alert( "Geolocation is not supported by this browser.")
      }
    }
    
     showPosition(position) {
     console.log("Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude  )  
    }
    
     showError(error) {
      switch(error.code) {
        case error.PERMISSION_DENIED:
          alert( " يبدو بأنك قمت مسبقا بمنع الوصول إلى بيانات الموقع ، يرجى إعطاء الصلاحية من إعدادات الموقع")
          break;
        case error.POSITION_UNAVAILABLE:
          alert(  "Location information is unavailable.")
          break;
        case error.TIMEOUT:
          alert(  "The request to get user location timed out.")
          break;
        case error.UNKNOWN_ERROR:
          alert(  "An unknown error occurred.")
          break;
      }
    }
}
