import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
url1="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/3.jpg?alt=media&token=6dffaa57-af67-4636-9aa1-4c442a77ca4d"
url2="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/%D9%84%D8%A8%D9%86.jpg?alt=media&token=d35cb7b6-d37c-40ac-815c-40e9be632541"
url3="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/%D9%84%D8%A8%D9%86%D8%A92.jpg?alt=media&token=3e1b05e3-2271-4b0f-9bb0-934f05d8231f"
url4="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/101038989_861860130976090_2460120192991100928_o.jpg?alt=media&token=e5684a44-e5f3-46e1-8fcc-d325b8e121eb"
url5="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/%D8%AC%D8%A8%D9%86%D8%A9%20%D9%85%D9%88%D8%B2%D8%A7%D8%B1%D9%8A%D9%84%D8%A7.jpg?alt=media&token=769a1a12-496e-4a8f-9c61-ecfdc189f822"
url6="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/%D8%AC%D8%A8%D9%86%D8%A9%20%D9%85%D8%B3%D9%86%D8%B1%D8%A9.jpg?alt=media&token=313bba50-a818-4ebf-b252-fb241652a3ca"
url7="https://firebasestorage.googleapis.com/v0/b/kahkeoglu.appspot.com/o/sorke.jpg?alt=media&token=10a75763-0bbf-4d98-8492-4145e57d7566"
url8
lang
items  
constructor() { }

  ngOnInit() {
    window.scrollTo({  top: 0,
      left: 0,
      behavior: 'smooth'});
      this.lang=AppComponent.lang
      this.items=[{image:this.url2,name:this.lang.Yogurt},{image:this.url3,name:this.lang.labne},{image:this.url1,name:this.lang.cheese1},{image:this.url5,name:this.lang.Mozzarella},{image:this.url6,name:this.lang.cheese},{image:this.url7,name:this.lang.sorke},{image:this.url4,name:this.lang.Margarine}]

  }

}
