import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
langstatus
lang
dir
  constructor() { }

  ngOnInit() {
this.langstatus=localStorage.getItem('pagel')
this.lang=AppComponent.lang
this.dir=localStorage.getItem('dir')
  }
gotof(){
  
  document.getElementById("factory").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
}
gotoc(){
  document.getElementById("connect").scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
}
gototop(){
  window.scrollTo({  top: 0,
    left: 0,
    behavior: 'smooth'});
}
ar(){
  AppComponent.lang=AppComponent.langar
  localStorage.setItem('pagel','ar')
  this.dir=AppComponent.dir
  localStorage.setItem("dir","rtl")
  location.reload()
}
en(){
  AppComponent.lang=AppComponent.langen
  localStorage.setItem('pagel','en')
  localStorage.setItem("dir","ltr")
  location.reload()
 
}
tr(){
  AppComponent.lang=AppComponent.langtr
  localStorage.setItem('pagel','tr')
  localStorage.setItem("dir","ltr")
  location.reload()
}
}
