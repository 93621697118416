import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
lang
  constructor() { }

  ngOnInit() {
    this.lang=AppComponent.lang
  }
gotomail(){
  
  location.href="tel:+90 539 964 06 06"
}
messenger(){
  location.href="https://m.me/KahkeciogluTR"
}
}
