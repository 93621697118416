import { Component } from '@angular/core';
import { app } from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'kahkefp';
  defaultValue
  
  langpage
  public static lang
  public static dir
  public static langen ={
    
    home: 'home',
    our_products: 'Our Products',
    success_story: 'Success Story',
    Manufacturing_quality: 'Manufacturing Quality',
    Connect_us: 'Contact us',
    Shop_from_our_products: 'Shop from our products',
    Know_the_stores_near_you: "Know the stores near you",
    Get_to_know_our_factory: "Get to know our factory",
    Follow_us: 'Follow us',
    Product_quality: 'Product Quality',
    Yogurt: 'Yogurt',
    labne: 'Labne',
    Mozzarella: 'Mozzarella',
    cheese: ' Cheese',
    cheese1: ' Cheese',
    Margarine: 'Margarine',
    sorke: 'Sorke',
    Select_your_current_loc: 'Select your current location to see the stores near you',
    my_location: 'select my location',
    distance: 'distance',
    M: 'M',
  KM: 'km',
  p1: 'kAHKE Company is one of the leading companies in the manufacture of dairy and cheese products established in 1934 in Aleppo by our great grandfather whose main business was the sale and distribution of milk, and since then this name carries a leading reputation with the consumer in Syria and in the neighboring Arab countries All the way to Turkey ',
  p2: "After eighty years of experience and success, a company was opened",
  p3: '.In Turkey, Gaziantep, we are proud of our team who work with great passion for the company,s success',
  p4: 'We at company (KAHKE) always strive to establish distinguished and strong relationships with companies and merchants, based on trust, long-term relationship and common interests to be the strategic partner specialized in the dairy and cheese industry at the local and international level.',
  p5: "It comes at the forefront of our priorities to maintain the high quality of our products and conform to the highest Turkish and international specifications and standards and as a result of this effort we have obtained several quality certificates which are",
p6: 'Quality Management System and International Standards System',
p7: 'food safety system',
p8: 'Hazard Analysis and Critical Control Points',
p9: 'Halal Certificate',
   }
   public static langtr ={
    
    home:'Ana Sayfa',
    
    our_products: 'Ürünlerimiz',
    success_story: 'Başarı Hikayesi',
    Manufacturing_quality: 'İmalat Kalitesi',
    Connect_us: 'Bize ulaşın',
    Shop_from_our_products: 'Ürünlerimizden alışveriş yapın',
    Know_the_stores_near_you: "Yakınınızdaki mağazaları tanıyın",
    Get_to_know_our_factory: "Fabrikamızı tanıyın",
    Follow_us: 'Bizi takip et',
    Product_quality: 'Ürün Kalitesi',
    Yogurt: 'Yoğurt',
    labne: 'Labne',
    Mozzarella: 'Mozzarella',
    cheese: 'Peynir',
    cheese1: 'Peynir',
    Margarine: 'Margarin',
    sorke: 'Sorke',
    Select_your_current_loc: 'Yakınınızdaki mağazaları görmek için mevcut konumunuzu seçin',
    my_location: 'konumumu seç',
    distance: 'mesafe',
    M: 'M',
  KM: 'km',
  p1: 'kAHKE Şirketi, 1934 yılında Halep,te ana işi sütün satışı ve dağıtımı olan büyük dedemiz tarafından kurulan süt ve peynir ürünlerinin üretiminde lider firmalardan biridir ve o zamandan beri bu isim Suriye,deki tüketici ile lider bir üne sahiptir. ve komşu Arap ülkelerinde Türkiye,ye kadar',
  p2: "Seksen yıllık deneyim ve başarıdan sonra bir şirket açıldı",
  p3: '.Türkiye, Gaziantep,te şirket için büyük bir tutkuyla çalışan ekibimizle gurur duyuyoruz',
  p4: 'Şirkette (KAHKE) her zaman yerel ve uluslararası süt ve peynir endüstrisinde uzmanlaşmış stratejik ortak olmak için güven, uzun vadeli ilişki ve ortak çıkarlara dayalı şirketler ve tüccarlarla seçkin ve güçlü ilişkiler kurmaya çalışıyoruz. seviye. ',
  p5: "Ürünlerimizin yüksek kalitesini korumak, en yüksek Türk ve uluslararası şartnamelere ve standartlara uymak önceliklerimizle ön plana çıkıyor ve bu çaba sonucunda çeşitli kalite sertifikaları aldık",
p6: 'Kalite Yönetim Sistemi ve Uluslararası Standartlar Sistemi',
p7: 'gıda güvenliği sistemi',
p8: 'Tehlike Analizi ve Kritik Kontrol Noktaları',
p9: 'Helal Sertifikası',
  }
  public static langar ={
    
    home:'الرئيسية',
    our_products :'منتجاتنا' ,
    success_story :'قصة نجاح' ,
    Manufacturing_quality:'جودة التصنيع' ,
    Connect_us:'اتصل بنا' ,
    Shop_from_our_products:'تسوق من منتجاتنا',
    Know_the_stores_near_you :'تعرف إلى المتاجر القريبة منك' ,
    Get_to_know_our_factory:'تعرف إلى معملنا' ,
    Follow_us :'تابعنا' ,
    Product_quality:'جودة المنتج',
    Yogurt :'اللبن' ,
    labne :'اللبنة' ,
    Mozzarella:'جبنة موزاريلا' ,
    cheese:'جبنة مسنرة' ,
    cheese1 :'جبنة مشلّلة' ,
    Margarine :'السمنة' ,
    sorke:'السوركه',
    Select_your_current_loc:'حدد موقعك الحالي لمعرفة المتاجر القريبة منك',
    my_location:'تحديد موقعي',
    distance:'يبعد عنك',
    M:'متر',
  KM:'كم',
  p1:'شركة كعكة هي من الشركات الرائدة في تصنيع منتجات الألبان والأجبان تأسست عام 1934 في حلب من قِبل جدنا الكبير الذي كان عمله الرئيسي هو بيع وتوزيع الحليب، ومنذ ذلك الحين وحتى الآن يحمل هذا الاسم سمعة رائدة عند المستهلك في سوريا وفي البلدان العربية المجاورة وصولاً إلى تركيا',
  p2:'وبعد ثمانون عاماً من الخبرة والنجاح تم افتتاح شركة',
  p3:'.في تركيا غازي عنتاب ونحن فخورون بفريقنا الذي يعمل بشغف كبير من أجل نجاح الشركة',
  p4:'نحن في شركة (كعكة) نسعى دائماً لإقامة علاقات مميزة وقوية مع الشركات والتُجار، مبنية على الثقة والعلاقة طويلة الأجل والمصالح المشتركة لنكون الشريك الاستراتيجي المتخصص في صناعة الالبان والأجبان على المستوى المحلي والدولي .',
  p5:'تأتي في مقدمة أولوياتنا المحافظة على الجودة العالية لمنتجاتنا والمطابقة لأعلى المواصفات والمقاييس التركية والعالمية  ونتيجة لهذا الجهد فقد حصلنا على عدة شهادات جودة وهي',
p6:'نظام معايير إدارة الجودة والمقاييس الدولية ',
p7:' نظام سلامة الأغذية ',
p8:'تحليل المخاطر ونقاط التحكم الحرجة',
p9:'شهادة حلال',


 

}
  constructor(){
    this.getUsersLocale()
    
  }
  
  getUsersLocale(){
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
     // return defaultValue;
      console.log(this.defaultValue)
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : this.defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
   // return lang;
    //alert(lang.substring(0,2 ))
    
    this.langpage=lang.substring(0,2 )
    this.setlang()
  }
  setlang(){
    console.log(this.langpage)
    //if(this.langpage !='ar' && this.langpage !='en' && this.langpage !='tr'){
   //   this.langpage="ar"
   // }
   if(localStorage.getItem('pagel')!=null){
    switch (localStorage.getItem('pagel')) {
      case 'en':
        AppComponent.lang=AppComponent.langen
       localStorage.setItem("dir","ltr")
        break;
        case 'ar':
          AppComponent.lang=AppComponent.langar
          localStorage.setItem("dir","rtl")
          break;
          case 'tr':
        AppComponent.lang=AppComponent.langtr
        localStorage.setItem("dir","ltr")
        break;
      default: AppComponent.lang=AppComponent.langar
      localStorage.setItem("dir","rtl")
        break;
    }
   }else{
    switch (this.langpage) {
      case 'en':
        AppComponent.lang=AppComponent.langen
        localStorage.setItem('pagel','en')
        localStorage.setItem("dir","ltr")
        break;
        case 'ar':
          AppComponent.lang=AppComponent.langar
          localStorage.setItem('pagel','ar')
          localStorage.setItem("dir","rtl")
          break;
          case 'tr':
        AppComponent.lang=AppComponent.langtr
        localStorage.setItem('pagel','tr')
        localStorage.setItem("dir","ltr")
        break;
      default: AppComponent.lang=AppComponent.langar
      localStorage.setItem('pagel','ar')
      localStorage.setItem("dir","rtl")
        break;
      }
    
    }}
 
}
