import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { ProductsComponent } from './products/products.component';
import { StoresComponent } from './stores/stores.component';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { FactoryComponent } from './factory/factory.component';
import { AgmCoreModule } from '@agm/core';

const routes:Routes = [
  {path:'' , redirectTo:'home' , pathMatch:'full'},
  {path:'home' , component:HomeComponent },
 {path:'products',component:ProductsComponent},
 {path:'stores',component:StoresComponent},
 {path:'factory',component:FactoryComponent},

]
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    ProductsComponent,
    StoresComponent,
    FactoryComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({apiKey:"AIzaSyDqEXgrY-4vP7t_XDsrcolnOu83uRQmU1g"}),

    RouterModule.forRoot(routes),
    AngularFireModule.initializeApp(environment),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
